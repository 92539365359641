import React from "react";
import ArticleIcon from "../icons/ArticleIcon";
import PencilIcon from "../icons/PencilIcon";

export default function Footer() {
  return (
    <div className="flex flex-col gap-8 mt-[100px] mb-[150px] xl:flex-row-reverse ">
      <div className="flex flex-col xl:w-[40%]">
        <div className="flex gap-3 mb-[30px]">
          <div className="flex cursor-pointer border p-2 rounded-lg gap-1 hover:border-primary hover:bg-blue-100">
            <ArticleIcon width={25} height={25} color="#006edb" />
            <p className="text-primary">See all help articles</p>
          </div>
          <div className="flex cursor-pointer border p-2 rounded-lg gap-1 hover:border-primary hover:bg-blue-100">
            <PencilIcon width={25} height={25} color="#006edb" />
            <p className="text-primary">Contact us</p>
          </div>
        </div>
        <div className="flex flex-col gap-4 ">
          <div className="flex justify-between md:flex-row flex-col gap-4">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-3">
                <p className="text-lg">About Yr</p>
                <div className="flex gap-2 xl:flex-col">
                  <a
                    href="https://info.nrk.no/personvernerklaering/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary underline"
                  >
                    Privacy
                  </a>
                  <a
                    href="https://info.nrk.no/informasjonskapsler/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary underline"
                  >
                    Cookies
                  </a>
                  <a
                    href="https://hjelp.yr.no/hc/en-us/articles/206550249"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary underline"
                  >
                    Our collaborators
                  </a>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex flex-col gap-3">
                  <p className="text-lg">Apps</p>
                  <div className="flex gap-2 xl:flex-col">
                    <a
                      href="https://apps.apple.com/jo/app/yr-no/id490989206"
                      target="_blank"
                      rel="noreferrer"
                      className="text-primary underline"
                    >
                      App for iOS
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=no.nrk.yr&hl=en&pli=1"
                      target="_blank"
                      rel="noreferrer"
                      className="text-primary underline"
                    >
                      App for Android
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-3">
                <p className="text-lg">Yr on social media</p>
                <div className="flex gap-2 xl:flex-col">
                  <a
                    href="https://web.facebook.com/profile.php?id=100064470083117"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary underline"
                  >
                    Yr on Facebook
                  </a>
                  <a
                    href="https://twitter.com/Meteorologene"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary underline"
                  >
                    Yr on Twitter
                  </a>
                  <a
                    href="https://www.instagram.com/yrbilder/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary underline"
                  >
                    Yr on Instagram
                  </a>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <p className="text-lg">Other services</p>
                <div className="flex gap-2 xl:flex-col">
                  <a
                    href="https://developer.yr.no/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary underline"
                  >
                    Yr for developers
                  </a>
                  <a
                    href="https://badetemperaturer.yr.no/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary underline"
                  >
                    Water temperatures
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 xl:self-start">
        <p className="text-sm">Moti Nesër is served by</p>
        <div className="flex items-center gap-6 py-4 mb-9">
          <img
            src="https://blog.weather.al/wp-content/uploads/2022/09/weather-01.png"
            alt=""
            className="w-[68px] h-[24px] cursor-pointer"
            onClick={() => window.open("https://weather.al/", "_blank")}
          />
          <img
            src="https://blog.weather.al/wp-content/uploads/2022/09/weather-01.png"
            alt=""
            className="w-[124px] h-[33px] cursor-pointer"
            onClick={() => window.open("https://weather.al/", "_blank")}
          />
        </div>
        <p className="text-md">
          Editor: Ingrid Støver Jensen <br />
          Executive editor: Vibeke Fürst Haugen <br />
          Meteorologically responsible: Roar Skålin
        </p>
        <p className="text-md mt-10 w-[85%]">
          Copyright © Norwegian Meteorological Institute and the Norwegian
          Broadcasting Corporation 2007-2023
        </p>
      </div>
    </div>
  );
}
