import React from "react";
import HomeItem from "../components/HomeItem";
import NavBar from "../components/NavBar";
import ShareIcon from "../icons/ShareIcon";

export default function HomeScreen() {
  return (
    <div>
      <NavBar />
      <h2 className="font-semibold text-2xl">My Locations</h2>
      <HomeItem />
      <h2 className="mt-10 font-semibold text-2xl">
        Other services from The Norwegian Meteorological Institute
      </h2>
      <div className="mt-6 flex w-ful gap-4 justify-between flex-wrap">
        <div
          className="flex items-center justify-center md:w-[50%] w-[100%] xl:w-[290px] xl:flex-1 h-[56px] cursor-pointer border-2 p-2 rounded-lg gap-1 border-blue-200 hover:border-primary bg-accent bg-opacity-50"
          onClick={() =>
            window.open(
              "https://www.met.no/en/weather-and-climate/subjective-forecast-chart",
              "_blank"
            )
          }
        >
          <p className="text-primary mr-2">Subjective forecast chart</p>
          <ShareIcon width={25} height={25} color="#006edb" />
        </div>
        <div
          className="flex items-center justify-center md:w-[50%] md:flex-1 w-[100%] xl:w-[290px] xl:flex-1 h-[56px] cursor-pointer border-2 p-2 rounded-lg gap-1 border-blue-200 hover:border-primary bg-accent bg-opacity-50"
          onClick={() =>
            window.open(
              "https://www.met.no/en/weather-and-climate/text-forecast-for-norway",
              "_blank"
            )
          }
        >
          <p className="text-primary mr-2">Text forecast for Norway</p>
          <ShareIcon width={25} height={25} color="#006edb" />
        </div>
        <div
          className="flex items-center justify-center md:w-[50%] w-[100%] xl:w-[290px] xl:flex-1 h-[56px] cursor-pointer border-2 p-2 rounded-lg gap-1 border-blue-200 hover:border-primary bg-accent bg-opacity-50"
          onClick={() =>
            window.open(
              "https://www.met.no/en/weather-and-climate/satellite-images",
              "_blank"
            )
          }
        >
          <p className="text-primary mr-2">Satellite images</p>
          <ShareIcon width={25} height={25} color="#006edb" />
        </div>
      </div>
    </div>
  );
}
