export default function MenuIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path fill={color} d="M3 18v-2h18v2Zm0-5v-2h18v2Zm0-5V6h18v2Z" />
    </svg>
  );
}
