import React from "react";
import ClockIcon from "../icons/ClockIcon";
import TemperatureIcon from "../icons/TemperatureIcon";
import UmbrellaIcon from "../icons/UmbrellaIcon";
import WindIcon from "../icons/WindIcon";

export default function WeatherCard({
  icon,
  temperature,
  precipitation,
  wind,
}) {
  return (
    <div className="rounded-lg md:w-fit p-3 md:bg-white overflow-hidden md:shadow-lg md:py-8 md:px-10 ">
      <div className="flex items-center gap-2">
        <ClockIcon width={20} height={20} color="#5A5A5A" />
        <p className="text-lg">Current conditions</p>
      </div>
      <div className="flex justify-between gap-10 ">
        <div className="flex items-center gap-2">
          <img src={icon} alt="" className="w-[100px] h-[100px]" />
          <p className="md:hidden">
            <span className="text-2xl text-red-600 ">{temperature}°</span>
          </p>
        </div>
        <div className="flex gap-2 self-end items-center md:gap-5 md:flex-row flex-col  ">
          <div className="flex gap-4 items-center self-start">
            <TemperatureIcon width={30} height={30} color="#5A5A5A" />
            <p className="ml-[-10px] text-sm md:text-lg md:flex md:gap-2 md:items-center md:justify-self-start">
              <span className="md:text-2xl hidden md:block text-red-600">
                {temperature}°
              </span>
              Feels like{" "}
              <span className="md:text-xl  text-red-600">{temperature}°</span>
            </p>
          </div>
          <div className="flex gap-4 items-center self-start">
            <UmbrellaIcon width={30} height={30} color="#5A5A5A" />
            <p className="ml-[-10px] text-sm md:text-lg text-blue-600">
              <span className="md:text-2xl ">{precipitation} </span>mm
            </p>
          </div>
          <div className="flex gap-4 items-center self-start">
            <WindIcon width={30} height={30} color="#5A5A5A" />
            <p className="ml-[-10px] text-sm md:text-lg text-gray-600">
              <span className="md:text-2xl ">{wind} </span>m/s
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
