import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import { useMediaQuery } from "react-responsive";

export default function HomeItem() {
  const [searchHistory, setSearchHistory] = useState([]);
  const [weatherInfo, setWeatherInfo] = useState([]);
  const [countryName, setCountryName] = useState();
  const [weatherObject, setWeatherObject] = useState({});
  const [iconObject, setIconObject] = useState({});
  const [day, setDay] = useState([]);
  const [countryId, setCountryId] = useState();

  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    let history = JSON.parse(localStorage.getItem("searchHistory" || "[]"));
    history && setSearchHistory(history.reverse());

    if (!history) {
      localStorage.setItem(
        "searchHistory",
        JSON.stringify([
          {
            name: "New York",
            country: "United States",
            admin1: "New York",
            lat: 40.71427,
            lng: -74.00597,
            elevation: 10,
            id: 5128581,
          },
          {
            name: "Cambodia",
            country: "Cambodia",
            lat: 13,
            lng: 105,
            elevation: 37,
            id: 1831722,
          },
          {
            name: "Albania",
            country: "Albania",
            lat: 41,
            lng: 20,
            elevation: 89,
            id: 783754,
          },
          {
            name: "Durrës",
            country: "Albania",
            admin1: "Qarku i Durrësit",
            lat: 41.32355,
            lng: 19.45469,
            id: 3185728,
          },
          {
            name: "Bangkok",
            country: "Thailand",
            admin1: "Bangkok",
            lat: 13.75398,
            lng: 100.50144,
            elevation: 12,
            id: 1609350,
          },
        ])
      );
    }
  }, []);

  useEffect(() => {
    const getWeatherInfo = async () => {
      let country = [];
      let id = [];
      try {
        searchHistory.slice(0, 5).map(async (search, i) => {
          const { data } =
            search.elevation === undefined
              ? await axios.get(
                  `https://api.met.no/weatherapi/locationforecast/2.0/?lat=${search.lat}&lon=${search.lng}`
                )
              : await axios.get(
                  `https://api.met.no/weatherapi/locationforecast/2.0/?lat=${search.lat}&lon=${search.lng}&altitude=${search.elevation}`
                );
          i === 0
            ? setWeatherInfo([data])
            : setWeatherInfo((current) => [...current, data]);
          country.push(search.name);
          setCountryName(country);
          id.push(search.id);
          setCountryId(id);
        });
      } catch (error) {
        console.log(error);
      }
    };

    getWeatherInfo();
  }, [searchHistory]);

  useEffect(() => {
    if (weatherInfo?.length > 0) {
      let currentHour = weatherInfo[0]?.properties?.meta?.updated_at
        .toString()
        .substring(0, 10);

      currentHour = new Date(currentHour).getTime() + 86400000;

      let dateArrIso =
        currentHour &&
        [...Array(3)].map(
          (arr, i) => (arr = new Date(currentHour + 86400000 * i).toISOString())
        );

      let date = [];
      let weekday = [];

      dateArrIso &&
        dateArrIso?.map((time) => {
          let hour = "T00:00:00Z";
          time = time.split("T")[0] + hour;
          weekday.push(dateFormat(new Date(time).getTime(), "dddd"));
          date.push(time);
        });

      setDay(weekday);

      let temp = {};
      let icon = {};

      weatherInfo?.map((weather, i) => {
        let name = countryName[i];
        let id = countryId[i];

        date &&
          date.map((time, j) => {
            if (!Object.keys(temp).includes(name)) {
              temp[name] = [
                Math.round(
                  weather?.properties?.timeseries?.find((x) => x.time === time)
                    .data.instant.details.air_temperature
                ),
              ];
              icon[name] = [
                weather?.properties?.timeseries?.find((x) => x.time === time)
                  .data.next_1_hours?.summary?.symbol_code ||
                  weather?.properties?.timeseries?.find((x) => x.time === time)
                    .data.next_6_hours?.summary?.symbol_code,
              ];
            } else {
              temp[name] = [
                ...temp[name],
                Math.round(
                  weather?.properties?.timeseries?.find((x) => x.time === time)
                    .data.instant.details.air_temperature
                ),
              ];
              icon[name] = [
                ...icon[name],
                weather?.properties?.timeseries?.find((x) => x.time === time)
                  .data.next_1_hours?.summary?.symbol_code ||
                  weather?.properties?.timeseries?.find((x) => x.time === time)
                    .data.next_6_hours?.summary?.symbol_code,
              ];
            }
          });
        temp[name].push(id);
      });

      setIconObject(icon);
      setWeatherObject(temp);
    }
  }, [weatherInfo, countryId]);

  return (
    <div>
      {searchHistory.length > 0 && (
        <div className="flex items-center p-2 justify-between border-b border-gray-300">
          <div className="md:w-[50%] w-[30%]"></div>
          <div className="flex flex-1 justify-between ">
            <p>
              {weatherInfo && isSmallScreen
                ? dateFormat(
                    new Date(
                      weatherInfo[0]?.properties?.meta?.updated_at
                    ).getTime(),
                    "ddd"
                  ) + "."
                : dateFormat(
                    new Date(
                      weatherInfo[0]?.properties?.meta?.updated_at
                    ).getTime(),
                    "dddd"
                  )}
            </p>
            {day.map((d, i) => (
              <p className={`last:hidden md:last:block } `} key={i}>
                {isSmallScreen ? d.substring(0, 3) + "." : d}
              </p>
            ))}
          </div>
        </div>
      )}

      {[...Array(Object.keys(weatherObject).length)].map((w, i) => (
        <div
          key={i}
          className="flex items-center p-2 justiy-between border-b border-gray-300 hover:bg-accent hover:bg-opacity-50 cursor-pointer"
          onClick={() => {
            let search = searchHistory.find(
              (data) => data.id == Object.values(weatherObject)[i][3]
            );

            navigate(
              `/forecast/${search.id}/${search?.country || search?.name}/${
                search?.admin1 || search?.name
              }/${search?.name}`
            );
          }}
        >
          <div className="md:w-[50%] w-[30%]">
            <p>{Object.keys(weatherObject)[i]}</p>
          </div>
          <div className="flex flex-1 justify-between">
            <div className="flex items-center gap-1">
              <img
                src={`/weathericon/${weatherInfo[i]?.properties?.timeseries[0]?.data.next_1_hours?.summary?.symbol_code}.png`}
                className="md:w-[50px] w-[40px]"
                alt=""
              />
              <p className="text-red-600">
                {Math.round(
                  weatherInfo[i]?.properties?.timeseries[0].data.instant.details
                    .air_temperature
                )}
                °
              </p>
            </div>
            <div className="flex items-center gap-1">
              <img
                src={`/weathericon/${Object.values(iconObject)[i][0]}.png`}
                className="md:w-[50px] w-[40px]"
                alt=""
              />
              <p className="text-red-600">
                {Object.values(weatherObject)[i][0]}°
              </p>
            </div>
            <div className="flex items-center gap-1">
              <img
                src={`/weathericon/${Object.values(iconObject)[i][1]}.png`}
                className="md:w-[50px] w-[40px]"
                alt=""
              />
              <p className="text-red-600">
                {Object.values(weatherObject)[i][1]}°
              </p>
            </div>
            <div className=" items-center gap-1 hidden md:flex">
              <img
                src={`/weathericon/${Object.values(iconObject)[i][2]}.png`}
                className="md:w-[50px] w-[40px]"
                alt=""
              />
              <p className="text-red-600">
                {Object.values(weatherObject)[i][2]}°
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
