import axios from "axios";
import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import WeatherCard from "../components/WeatherCard";
import WeatherItems from "../components/WeatherItems";
import dateFormat from "dateformat";
import PulseLoader from "react-spinners/PulseLoader";
import { useNavigate, useParams } from "react-router-dom";
import WeatherGraph from "../components/WeatherGraph";
import { Helmet } from "react-helmet";
import { Configuration, OpenAIApi } from "openai";
import InfoIcon from "../icons/InfoIcon";

export default function Forecast() {
  const navigate = useNavigate();
  const [weather, setWeather] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [error, setError] = useState("");
  const [country, setCountry] = useState({});
  const [isGraphShow, setIsGraphShow] = useState(false);
  const [graphSelected, setGraphSelected] = useState("");
  const [locationInfo, setLocationInfo] = useState("");

  const { id } = useParams();

  let currentHour = weather?.properties?.meta?.updated_at
    .toString()
    .substring(0, 13);

  let currentWeatherIndex =
    weather?.properties?.timeseries.findIndex((date) =>
      date?.time.includes(currentHour)
    ) !== -1
      ? weather?.properties?.timeseries.findIndex((date) =>
          date?.time.includes(currentHour)
        )
      : 0;

  let date = new Date(
    weather?.properties?.timeseries[currentWeatherIndex].time
  ).getTime();

  // format date array
  let dateArr = [...Array(9)].map(
    (arr, i) => (arr = dateFormat(date + 86400000 * i, "dddd d mmm"))
  );

  // replace the first date with 'Today'
  let dateArrFirstElement = dateArr[0].split(" ");
  dateArrFirstElement[0] = "Today";
  dateArr[0] = dateArrFirstElement.join(" ");

  let dateArrIso =
    date &&
    [...Array(9)].map(
      (arr, i) =>
        (arr = new Date(date + 86400000 * i).toISOString().substring(0, 10))
    );

  const getWeatherTable = (date) => {
    let table = [];
    weather?.properties?.timeseries?.forEach((x) => {
      if (x.time.includes(date)) {
        let obj = {
          time: x.time.substring(11, 13),
          temp: Math.round(x?.data?.instant?.details?.air_temperature),
          precipitation:
            x?.data?.next_1_hours?.details?.precipitation_amount ||
            x?.data?.next_6_hours?.details?.precipitation_amount,
          wind: Math.round(x?.data?.instant?.details?.wind_speed),
          icon: x?.data?.next_1_hours?.summary?.symbol_code,
          dew_point: Math.round(
            x?.data?.instant?.details?.dew_point_temperature
          ),
          pressure: Math.round(
            x?.data?.instant?.details?.air_pressure_at_sea_level
          ),
          humidity: Math.round(x?.data?.instant?.details?.relative_humidity),
        };
        table.push(obj);
      }
    });
    if (table?.length < 5 && table?.length > 0) {
      table[0].time = "01-07";
      table[1].time = "07-13";
      table[2].time = "13-19";
      table[3].time = "19-01";
    }
    return table;
  };

  const chatGptInfo = async (name, country) => {
    const configuration = new Configuration({
      apiKey: "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",
    });
    const openai = new OpenAIApi(configuration);

    const response = await openai.createCompletion({
      model: "text-davinci-003",
      prompt: `Tell me in detail about this location: ${name}, ${country}`,
      temperature: 0.2,
      max_tokens: 2048,
      top_p: 1,
      frequency_penalty: 0.0,
      presence_penalty: 0.6,
      stop: [" Human:", " AI:"],
    });

    return response.data.choices[0].text;
  };

  useEffect(() => {
    if (!id) {
      navigate("/");
    }

    const getWeather = async () => {
      try {
        setLoading(true);

        const { data: countryInfo } = await axios.get(
          `https://geocoding-api.open-meteo.com/v1/get?id=${id}`
        );

        const { data } =
          countryInfo.elevation === undefined
            ? await axios.get(
                `https://api.met.no/weatherapi/locationforecast/2.0/?lat=${countryInfo.latitude}&lon=${countryInfo.longitude}`
              )
            : await axios.get(
                `https://api.met.no/weatherapi/locationforecast/2.0/?lat=${countryInfo.latitude}&lon=${countryInfo.longitude}&altitude=${countryInfo.elevation}`
              );

        setCountry(countryInfo);

        setWeather(data);

        setLoading(false);

        const fetchData = async () => {
          try {
            setLoadingInfo(true);
            const { data } = await axios.get(
              `https://server.motineser.com/city/${id}`
            );
            if (data.length > 0) {
              setLocationInfo(data[0]?.city_info);
            } else {
              setLoadingInfo(true);
              const locationDetail = await chatGptInfo(
                countryInfo?.name,
                countryInfo?.country
              );
              await axios.post("https://server.motineser.com/city", {
                id,
                city_info: locationDetail,
              });
              setLocationInfo(locationDetail);
            }
            setLoadingInfo(false);
          } catch (e) {
            setLoadingInfo(false);
            console.log(e);
          }
        };

        fetchData();
      } catch (error) {
        setLoading(false);
        setError(error?.message);
      }
    };

    getWeather();
  }, [id]);

  useEffect(() => {}, []);

  useEffect(() => {
    dateArrIso && setGraphSelected(dateArrIso[0]);
  }, [isGraphShow]);

  return (
    <div>
      {loading ? (
        <>
          <NavBar />
          <div className="text-center mt-4">
            <PulseLoader color="#006edb" loading={loading} size={20} />
          </div>
        </>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <>
          <Helmet>
            <title>{`Yr - ${country?.name} - Long term forecast`}</title>
            <meta
              name="description"
              content={`Weather forecast for ${country?.name} for the next 9 days.`}
            />
            <meta name="description" content={locationInfo?.trim()} />
            <meta name="keywords" content="Weather, Yr, Yr.no, Forecast" />
          </Helmet>
          <NavBar
            country={country?.name}
            subtitle={`${country?.admin1 || ""} ${
              country?.country_code || ""
            } (${country?.country || ""}),
            elevation ${country?.elevation || ""} m`}
          />
          <div className="absolute z-0 bg-gradient-to-b from-accent to-transparent w-screen h-[200px] right-0 "></div>

          <div className="relative flex flex-col gap-8 mt-[20px]">
            <WeatherCard
              icon={`/weathericon/${weather?.properties?.timeseries[currentWeatherIndex].data?.next_1_hours?.summary?.symbol_code}.png`}
              temperature={Math.round(
                weather?.properties?.timeseries[currentWeatherIndex].data
                  ?.instant?.details?.air_temperature
              )}
              precipitation={Math.round(
                weather?.properties?.timeseries[currentWeatherIndex].data
                  ?.next_1_hours?.details?.precipitation_amount
              )}
              wind={Math.round(
                weather?.properties?.timeseries[currentWeatherIndex].data
                  ?.instant?.details?.wind_speed
              )}
            />
            <div className="mx-auto bg-slate-200 w-fit p-1 rounded-lg">
              <button
                className={`py-2 px-14 font-medium ${
                  !isGraphShow && "bg-white text-primary"
                } rounded-lg`}
                onClick={() => setIsGraphShow(false)}
              >
                Table
              </button>
              <button
                className={`py-2 px-14 font-medium ${
                  isGraphShow && "bg-white text-primary "
                } rounded-lg`}
                onClick={() => setIsGraphShow(true)}
              >
                Graph
              </button>
            </div>
            {!isGraphShow ? (
              <div>
                <div className="flex p-2 justify-between border-b border-gray-300 ">
                  <div className="w-[40%]"></div>
                  <div className="flex flex-1 justify-between ">
                    <p className="text-sm mr-[-10px]">Night</p>
                    <p className="text-sm md:ml-[35px]">Day</p>
                    <p className="text-sm lg:mr-[-30px]">Evening</p>
                    <p className="text-sm hidden lg:block">Max/min temp.</p>
                    <p className="text-sm hidden lg:block mr-[20px]">Precip.</p>
                    <p className="text-sm hidden lg:block">Wind.</p>
                  </div>
                </div>
                <div className="flex flex-col">
                  <WeatherItems
                    weather={weather}
                    date={date}
                    getWeatherTable={getWeatherTable}
                    dateArr={dateArr}
                    dateArrIso={dateArrIso}
                  />
                </div>
                {console.log(weather?.properties?.meta?.updated_at)}
                <p className="text-sm mt-2">
                  Updated{" "}
                  {dateFormat(
                    new Date(weather?.properties?.meta?.updated_at).getTime(),
                    "h:MM:ss TT"
                  )}{" "}
                </p>
              </div>
            ) : (
              <div>
                <div className="mx-auto bg-slate-200 w-fit h-fit p-1 rounded-lg">
                  <div className="">
                    <select
                      data-te-select-init
                      onChange={(e) => setGraphSelected(e.target.value)}
                      className="py-2 px-14 cursor-pointer font-medium rounded-lg text-primary outline-none"
                      style={{ textAlignLast: "center" }}
                    >
                      {dateArr?.map((date, i) => (
                        <option className="text-center" value={dateArrIso[i]}>
                          {date}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <WeatherGraph
                  weather={weather}
                  date={date}
                  getWeatherTable={getWeatherTable}
                  graphSelected={graphSelected}
                />
              </div>
            )}
            <div className="mt-5">
              <div className="flex gap-3 items-center ">
                <InfoIcon width={30} height={30} color="#006edb" />
                <h1 className="text-3xl text-primary font-bold">Info</h1>
              </div>
              {loadingInfo ? (
                <div className="text-center mt-4">
                  <PulseLoader
                    color="#006edb"
                    loading={loadingInfo}
                    size={20}
                  />
                </div>
              ) : (
                <p className="mt-3 leading-8">{locationInfo}</p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
