import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";

export default function WeatherGraph({
  weather,
  date,
  getWeatherTable,
  graphSelected,
}) {
  const [dataTemp, setDataTemp] = useState({});
  const [dataPrecipitation, setDataPrecipitation] = useState({});
  const [dataWind, setDataWind] = useState({});
  const [dataPressure, setDataPressure] = useState({});
  const [dataHumidity, setDataHumidity] = useState({});

  const optionsTemp = {
    legend: {
      display: true,
      position: "bottom",
    },
    title: {
      display: true,
      text: "Air and dew point temperature",
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        title: () => {
          return "Expected temperature";
        },
        label: function (tooltipItem, data) {
          return tooltipItem.value + "°";
        },
      },
    },

    scales: {
      xAxes: [
        {
          //   type: "time",
          //   time: {
          //     format: "MM/DD/YY",
          //     tooltipFormat: "ll",
          //   },
        },
      ],
      yAxes: [
        {
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              return value + "°";
            },
          },
        },
      ],
    },
  };

  const optionsPrecipitation = {
    legend: {
      display: true,
      position: "bottom",
    },
    title: {
      display: true,
      text: "Precipitation",
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        title: () => {
          return "Expected precipitation";
        },
        label: function (tooltipItem, data) {
          return tooltipItem.value + " mm";
        },
      },
    },

    scales: {
      xAxes: [
        {
          //   type: "time",
          //   time: {
          //     format: "MM/DD/YY",
          //     tooltipFormat: "ll",
          //   },
        },
      ],
      yAxes: [
        {
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              return value;
            },
          },
        },
      ],
    },
  };

  const optionsWind = {
    legend: {
      display: true,
      position: "bottom",
    },
    title: {
      display: true,
      text: "Wind",
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        title: () => {
          return "Expected wind";
        },
        label: function (tooltipItem, data) {
          return tooltipItem.value + "m/s";
        },
      },
    },

    scales: {
      xAxes: [
        {
          //   type: "time",
          //   time: {
          //     format: "MM/DD/YY",
          //     tooltipFormat: "ll",
          //   },
        },
      ],
      yAxes: [
        {
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              return value;
            },
          },
        },
      ],
    },
  };

  const optionsPressure = {
    legend: {
      display: true,
      position: "bottom",
    },
    title: {
      display: true,
      text: "Pressure",
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        title: () => {
          return "Pressure";
        },
        label: function (tooltipItem, data) {
          return tooltipItem.value + " hPa";
        },
      },
    },

    scales: {
      xAxes: [
        {
          //   type: "time",
          //   time: {
          //     format: "MM/DD/YY",
          //     tooltipFormat: "ll",
          //   },
        },
      ],
      yAxes: [
        {
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              return value;
            },
          },
        },
      ],
    },
  };

  const optionsHumidity = {
    legend: {
      display: true,
      position: "bottom",
    },
    title: {
      display: true,
      text: "Humidity",
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        title: () => {
          return "Humidity";
        },
        label: function (tooltipItem, data) {
          return tooltipItem.value + " %";
        },
      },
    },

    scales: {
      xAxes: [
        {
          //   type: "time",
          //   time: {
          //     format: "MM/DD/YY",
          //     tooltipFormat: "ll",
          //   },
        },
      ],
      yAxes: [
        {
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              return value;
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    let table = getWeatherTable(graphSelected);
    let hours = [];
    let temp = [];
    let dew = [];
    let precipitation = [];
    let wind = [];
    let pressure = [];
    let humidity = [];

    table?.map((time) => {
      temp.push(time.temp);
      hours.push(time.time);
      dew.push(time.dew_point);
      precipitation.push(time.precipitation);
      wind.push(time.wind);
      pressure.push(time.pressure);
      humidity.push(time.humidity);
    });

    setDataTemp({
      labels: hours,
      datasets: [
        {
          label: "Temperature °C",
          data: temp,
          fill: false,
          borderColor: "rgb(255, 0, 0)",
        },
        {
          label: "Dew point °C",
          data: dew,
          fill: false,
          borderColor: "rgb(0, 0,0)",
        },
      ],
    });

    setDataPrecipitation({
      labels: hours,
      datasets: [
        {
          label: "Precipitation mm",
          data: precipitation,
          fill: true,
          backgroundColor: "#006edb",
        },
      ],
    });

    setDataWind({
      labels: hours,
      datasets: [
        {
          label: "Wind m/s",
          data: wind,
          fill: false,
          borderColor: "#be2ed6",
        },
      ],
    });

    setDataHumidity({
      labels: humidity,
      datasets: [
        {
          label: "Humidity %",
          data: humidity,
          fill: false,
          borderColor: "#2f2f2f",
        },
      ],
    });

    setDataPressure({
      labels: pressure,
      datasets: [
        {
          label: "Pressure hPa",
          data: wind,
          fill: false,
          borderColor: "#00ab41",
        },
      ],
    });
  }, [graphSelected]);

  return (
    <div className="flex flex-col gap-6 mt-[30px]">
      <div className="h-[300px]">
        <Line data={dataTemp} options={optionsTemp} />
      </div>
      <div className="h-[200px]">
        <Bar data={dataPrecipitation} options={optionsPrecipitation} />
      </div>
      <div className="h-[200px]">
        <Line data={dataWind} options={optionsWind} />
      </div>
      <div className="h-[200px]">
        <Line data={dataPressure} options={optionsPressure} />
      </div>
      <div className="h-[200px]">
        <Line data={dataHumidity} options={optionsHumidity} />
      </div>
    </div>
  );
}
