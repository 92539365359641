import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LocationIcon from "../icons/LocationIcon";
import SearchIcon from "../icons/SearchIcon";

export default function Search({ isSearchOpen, setIsSearchOpen, inputRef }) {
  const navigate = useNavigate();

  const [input, setInput] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const inputBoxRef = useRef(null);

  useEffect(() => {
    if (isSearchOpen) {
      inputBoxRef.current.focus();
    }
  }, [isSearchOpen]);

  useEffect(() => {
    const getSearchData = async () => {
      const { data } = await axios.get(
        `https://geocoding-api.open-meteo.com/v1/search?name=${input}`
      );
      setSearchResult(data.results);
      return data;
    };
    getSearchData();
  }, [input]);

  return (
    <>
      {isSearchOpen ? (
        <div ref={inputRef} className="flex w-full relative">
          <div className="flex w-full cursor-pointer border p-2 rounded-lg gap-1 border-primary ">
            <SearchIcon width={25} height={25} color="#006edb" />
            <input
              ref={inputBoxRef}
              className="w-full bg-transparent outline-none font-medium"
              type="text"
              placeholder="Search by location or coordinates"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          </div>
          {searchResult && (
            <div className="absolute left-0 top-[60px] bg-white z-[99] w-full p-4 rounded-lg shadow-lg max-h-[500px] overflow-y-auto">
              {searchResult?.map((search, i) => (
                <div
                  key={search.id}
                  className="flex w-[100%] items-center gap-2 p-3 border-b last:border-none  border-gray-400 cursor-pointer hover:bg-blue-100 hover:rounded-lg hover:border-blue-100 "
                  onClick={() => {
                    localStorage.setItem(
                      "searchHistory",
                      JSON.stringify([
                        ...(JSON.parse(localStorage.getItem("searchHistory")) ||
                          ""),
                        {
                          name: search.name,
                          country: search.country,
                          admin1: search.admin1,
                          lat: search.latitude,
                          lng: search.longitude,
                          elevation: search.elevation,
                          id: search.id,
                        },
                      ])
                    );
                    setIsSearchOpen(false);

                    navigate(
                      `/forecast/${search.id}/${
                        search?.country || search?.name
                      }/${search?.admin1 || search?.name}/${search?.name}`
                    );
                  }}
                >
                  <LocationIcon width={30} height={30} color="#000" />
                  <div>
                    <h3 className="md:text-md text-sm">{search.name}</h3>
                    <p className="md:text-md text-sm">
                      {search.admin1} {search.country_code} ({search.country}),
                      elevation {search.elevation} m
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div
          className="flex cursor-pointer border p-2 rounded-lg gap-1 hover:border-primary hover:bg-blue-100"
          onClick={() => setIsSearchOpen(!isSearchOpen)}
        >
          <SearchIcon width={25} height={25} color="#006edb" />
          <p className="text-primary hidden md:block">Search</p>
        </div>
      )}
    </>
  );
}
