import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Forecast from "./screen/Forecast";
import HomeScreen from "./screen/HomeScreen";

function App() {
  return (
    <div className="xl:px-[200px] md:px-[20px] px-[10px] selection:bg-accent ">
      <BrowserRouter>
        <Helmet>
          <title>Yr</title>
          <meta
            name="description"
            content="Weather forecast. Hourly, long term and precipitation map from NRK and Meteorologisk institutt."
          />
          <meta name="keywords" content="Weather, Yr, Yr.no, Forecast" />
        </Helmet>
        <main>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route
              path="/forecast/:id/:country/:admmin1/:name"
              element={<Forecast />}
            />
          </Routes>
        </main>
        <div>
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
