import React, { useEffect, useState } from "react";
import WeatherPopUp from "./WeatherPopUp";

export default function WeatherItems({
  weather,
  date,
  getWeatherTable,
  dateArr,
  dateArrIso,
}) {
  const [weatherInfo, setWeatherInfo] = useState([]);
  const [weatherPopUp, setWeatherPopUp] = useState("");
  const [dateSelected, setDateSelected] = useState("");

  useEffect(() => {
    let tmp = [];

    dateArr.map((date, i) => {
      tmp.push({ date });
    });

    // date arr as type ISOString

    let obj = {};
    let precipitationObj = {};
    let windObj = {};
    let iconObj = {};

    weather?.properties?.timeseries?.forEach((x) => {
      dateArrIso.map((date) => {
        if (x.time.includes(date)) {
          if (!Object.keys(obj).includes(date)) {
            obj[date] = [
              Math.round(x?.data?.instant?.details?.air_temperature),
            ];
            precipitationObj[date] = [
              x?.data?.next_6_hours?.details?.precipitation_amount || 0,
            ];
            windObj[date] = [Math.round(x?.data?.instant?.details?.wind_speed)];
            iconObj[date] = [
              x?.data?.next_1_hours?.summary?.symbol_code || "cloudy",
              x?.data?.next_6_hours?.summary?.symbol_code || "cloudy",
              x?.data?.next_12_hours?.summary?.symbol_code || "cloudy",
            ];
          } else {
            obj[date] = [
              ...obj[date],
              Math.round(x?.data?.instant?.details?.air_temperature),
            ];
            precipitationObj[date] = [
              ...precipitationObj[date],
              x?.data?.next_6_hours?.details?.precipitation_amount || 0,
            ];
            windObj[date] = [
              ...windObj[date],
              Math.round(x?.data?.instant?.details?.wind_speed),
            ];
          }
        }
      });
    });

    tmp.map((weather, i) => {
      let temp = Object.values(obj)[i];
      let precipitation = Object.values(precipitationObj)[i];
      let wind = Object.values(windObj)[i];
      let icon = Object.values(iconObj)[i];

      weather.min = temp && Math.min(...temp);
      weather.max = temp && Math.max(...temp);
      weather.precipitation =
        precipitation &&
        precipitation.reduce((partialSum, a) => partialSum + a, 0).toFixed(1);
      weather.wind =
        wind &&
        (
          wind.reduce((partialSum, a) => partialSum + a, 0) / wind.length
        ).toFixed(1);
      weather.icon = icon;
    });

    setWeatherInfo(tmp);
  }, [date]);

  const [weatherTable, setWeatherTable] = useState([]);

  useEffect(() => {
    let weatherArr = [];

    weatherArr = getWeatherTable(weatherPopUp);

    setWeatherTable(weatherArr);
  }, [dateSelected]);

  return (
    <>
      {weatherInfo.map((info, i) => (
        <div
          key={i}
          className="flex p-2 cursor-pointer justify-between border-b border-gray-300  hover:bg-accent hover:bg-opacity-50"
          onClick={() => {
            setWeatherPopUp(dateArrIso[i]);
            setDateSelected(info.date);
          }}
        >
          <div className="flex flex-col w-[40%] justify-center">
            <p>{info.date}.</p>
            <p className="text-lg text-red-600 lg:hidden">
              {info.max}°/{info.min}°
            </p>
            <p className="text-primary lg:hidden">{info.precipitation} mm</p>
            <p className="lg:hidden">{info.wind} m/s</p>
          </div>
          <div className="flex  flex-1 items-center justify-between">
            {info.icon.map((pic, i) => (
              <div key={i} className="flex">
                <img
                  src={`/weathericon/${pic}.png`}
                  alt=""
                  className="w-[50px]"
                />
              </div>
            ))}
            <div className="flex hidden lg:block">
              <p className="text-lg text-red-600">
                {info.max}°/{info.min}°
              </p>
            </div>
            <div className="flex hidden lg:block">
              <p className="text-primary lg">{info.precipitation} mm</p>
            </div>
            <div className="flex hidden lg:block">
              <p className="">{info.wind} m/s</p>
            </div>
          </div>
        </div>
      ))}
      {weatherPopUp && (
        <WeatherPopUp
          setWeatherPopUp={setWeatherPopUp}
          dateSelected={dateSelected}
          setDateSelected={setDateSelected}
          weatherTable={weatherTable}
          dateArr={dateArr}
          dateArrIso={dateArrIso}
        />
      )}
    </>
  );
}
