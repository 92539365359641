import React, { useEffect, useRef, useState } from "react";
import ArrowLeftIcon from "../icons/ArrowLeftIcon";
import ArrowRightIcon from "../icons/ArrowRightIcon";
import CloseIcon from "../icons/CloseIcon";
import useClickOutside from "../utils/clickOutside";

export default function WeatherPopUp({
  setWeatherPopUp,
  dateSelected,
  weatherTable,
  dateArr,
  setDateSelected,
  dateArrIso,
}) {
  const [dateIndex, setDateIndex] = useState();

  const modalRef = useRef(null);
  useClickOutside(modalRef, () => {
    setWeatherPopUp("");
  });

  useEffect(() => {
    let i = dateArr.findIndex((x) => x === dateSelected);
    setDateIndex(i);
  }, []);

  return (
    <>
      <div className="fixed inset-0 bg-black/50"></div>
      <div className="fixed top-[50%] left-0 right-0 z-50 md:mt-[-20px]  translate-y-[-50%] flex justify-center items-center w-full h-full md:p-10 overflow-x-hidden scrollbar-hide">
        <div
          className={`relative w-full h-full md:w-[90%] ${
            weatherTable?.length < 5 && "flex items-center justify-center"
          }`}
        >
          <div ref={modalRef} className="relative bg-white rounded-lg shadow">
            <div className="flex items-center justify-between lg:p-10 p-6 rounded-t dark:border-gray-600">
              <h3 className="text-2xl font-semibold ">{dateSelected}</h3>
              <button
                type="button"
                className="text-black bg-transparent hover:bg-blue-100 hover:text-primary rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                onClick={() => setWeatherPopUp("")}
              >
                <CloseIcon />
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="lg:px-10 p-4 space-y-6">
              <table className="w-full text-left text-sm">
                <thead className="border-b dark:border-neutral-500">
                  <tr>
                    <th scope="col" className="lg:px-6 px-2 md:py-4">
                      Time
                    </th>
                    <th scope="col" className="lg:px-6 px-2 lg:py-4">
                      Weather
                    </th>
                    <th scope="col" className="lg:px-6 px-2 lg:py-4">
                      Temp.
                    </th>
                    <th scope="col" className="lg:px-6 px-2 lg:py-4">
                      Precip. mm
                    </th>
                    <th scope="col" className="lg:px-6 px-2 lg:py-4">
                      Wind speed m/s
                    </th>
                    <th scope="col" className="md:px-6 md:py-4 md:block hidden">
                      Wind desc.
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {weatherTable?.map((info, i) => (
                    <tr
                      key={i}
                      className="border-b dark:border-neutral-500 text-md"
                    >
                      <td className="whitespace-nowrap md:px-6 px-2 py-4 font-medium">
                        {info.time}
                      </td>
                      <td className="whitespace-nowrap md:px-6 px-2 py-4">
                        <img
                          src={`/weathericon/${info.icon}.png`}
                          alt=""
                          className="w-[50px]"
                        />
                      </td>
                      <td className="whitespace-nowrap md:px-6 px-2 py-4 text-red-600 ">
                        {info.temp}°
                      </td>
                      <td className="whitespace-nowrap md:px-6 px-2 py-4 text-primary">
                        {info.precipitation === 0 ? "" : info.precipitation}
                      </td>
                      <td className="whitespace-nowrap md:px-6 px-2 py-4 ">
                        {info.wind}
                      </td>
                      <td className="whitespace-nowrap md:px-6 px-2 py-4 mt-3 md:block  hidden">
                        Light breeze from south west
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex md:p-10 px-4 py-5 justify-between">
              <div
                className={`${
                  dateIndex === 0 && "invisible"
                } flex cursor-pointer border items-center p-2 rounded-lg gap-1 hover:border-primary hover:bg-blue-100`}
                onClick={() => {
                  setDateIndex(dateIndex - 1);
                  setDateSelected(dateArr[dateIndex - 1]);
                  setWeatherPopUp(dateArrIso[dateIndex - 1]);
                }}
              >
                <ArrowLeftIcon width={20} height={20} color="#006edb" />
                <p className="text-primary ">Previous day</p>
              </div>
              <div
                className={`
              ${dateIndex === dateArr.length - 1 && "invisible"}
              flex cursor-pointer border items-center p-2 rounded-lg gap-1 hover:border-primary hover:bg-blue-100`}
                onClick={() => {
                  setDateIndex(dateIndex + 1);
                  setDateSelected(dateArr[dateIndex + 1]);
                  setWeatherPopUp(dateArrIso[dateIndex + 1]);
                }}
              >
                <p className="text-primary">Next day</p>
                <ArrowRightIcon width={20} height={20} color="#006edb" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
