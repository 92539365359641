import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../icons/CloseIcon";
import MenuIcon from "../icons/MenuIcon";
import useClickOutside from "../utils/clickOutside";
import Search from "./Search";

export default function NavBar({ country, subtitle }) {
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const inputRef = useRef(null);
  useClickOutside(inputRef, () => {
    setIsSearchOpen(false);
  });

  const menuRef = useRef(null);
  useClickOutside(menuRef, () => {
    setIsMenuOpen(false);
  });

  return (
    <div className="flex flex-col mt-4">
      <div className="self-end">
        <p className="text-sm hidden md:block">
          Served by the Norwegian Meteorological Institute and NRK
        </p>
      </div>
      <div className="flex justify-between items-center gap-6 mb-5">
        <div className="flex items-center gap-2">
          <img
            src="/logo.png"
            onClick={() => navigate("/")}
            className={`md:w-[100px] w-[50px] cursor-pointer ${
              isSearchOpen && "hidden md:block"
            }`}
            alt=""
          />
          {!isSearchOpen && (
            <div className="flex flex-col w-full">
              <h2 className="text-2xl font-bold">{country}</h2>
              <div className="w-[50vw] ">
                <p className="truncate">{subtitle}</p>
              </div>
            </div>
          )}
        </div>
        <div className={`flex ${isSearchOpen ? "flex-1" : ""} gap-4`}>
          <Search
            inputRef={inputRef}
            isSearchOpen={isSearchOpen}
            setIsSearchOpen={setIsSearchOpen}
          />

          <div className="relative">
            <div
              className="flex cursor-pointer border p-2 rounded-lg gap-1 hover:border-primary hover:bg-blue-100"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <MenuIcon width={25} height={25} color="#006edb" />
              <p className="text-primary hidden md:block ">Menu</p>
            </div>
            {isMenuOpen && (
              <div
                ref={menuRef}
                className="z-[99] absolute flex flex-col px-4 py-3 rounded-lg shadow-lg top-14 right-0 md:min-w-[500px] min-w-[80vw] bg-white"
              >
                <div className="self-end mb-5 cursor-pointer">
                  <button
                    type="button"
                    className="text-black bg-transparent hover:bg-blue-100 hover:text-primary rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <CloseIcon />
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="flex flex-col mb-12">
                  <div className="py-4 border-b first:border-t border-gray-300 cursor-pointer hover:bg-accent hover:bg-opacity-50">
                    <p className="ml-3">About Me</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
