import React from "react";

export default function InfoIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 36 36"
    >
      <path
        fill={color}
        d="M25.39 25.45a1 1 0 0 0-1.38.29c-1.41 2.16-4 4.81-6.31 5.7s-4.12.57-4.84 0c-.31-.27-1.12-1-.43-3.49c.46-1.66 3.32-9.48 4-11.38l-2.18.28c-.69 1.86-3.29 8.84-3.76 10.58c-.68 2.49-.34 4.3 1.09 5.56A5.59 5.59 0 0 0 15 34a9.53 9.53 0 0 0 3.45-.7c2.79-1.09 5.72-4.12 7.26-6.47a1 1 0 0 0-.32-1.38Z"
        class="clr-i-outline clr-i-outline-path-1"
      />
      <path
        fill={color}
        d="M19.3 11a4.5 4.5 0 1 0-4.5-4.5a4.5 4.5 0 0 0 4.5 4.5Zm0-7a2.5 2.5 0 1 1-2.5 2.5A2.5 2.5 0 0 1 19.3 4Z"
        class="clr-i-outline clr-i-outline-path-2"
      />
      <path
        fill={color}
        d="M11.81 15c.06 0 6.27-.82 7.73-1c.65-.1 1.14 0 1.3.15s.21.8-.07 1.68c-.61 1.86-3.69 11-4.59 13.71a8 8 0 0 0 1.29-.38a7.32 7.32 0 0 0 1.15-.6c1.23-3.56 3.53-10.46 4.05-12.04s.39-2.78-.3-3.6a3.16 3.16 0 0 0-3.08-.83c-1.43.15-7.47.94-7.73 1a1 1 0 0 0 .26 2Z"
        class="clr-i-outline clr-i-outline-path-3"
      />
      <path fill="none" d="M0 0h36v36H0z" />
    </svg>
  );
}
